import React from 'react';
import styles from './CTA.module.scss';

const CTA = () => (
  <div className={styles.container}>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://marcusglowe.typeform.com/to/a3OrjdfL"
    >
      <div className={styles.cta}>sign up for waitlist</div>
    </a>
    <div className={styles.ctaSubtext}>(currently in beta)</div>
  </div>
);

export default CTA;
