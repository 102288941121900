import React from 'react';
import { Link } from 'gatsby';
import styles from './Logo.module.scss';

const Logo = () => {
  return (
    <Link to="/">
      <div className={styles.logo}>{'APLOZZ'.split('').map((letter) => <div>{letter}</div>)}</div>
      <div className={styles.subtitle}>
        Animate with your face.
        <br />
        Make any character come to life.
        <br />
        Share your videos anywhere.
        <br />
      </div>
    </Link>
  );
};

export default Logo;
