import React from 'react';
import { withPrefix } from 'gatsby';
import styles from './BackgroundVideo.module.scss';

const BackgroundVideo = () => {
  return (
    <div
      className={styles.bg}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100%',
        width: '100%',
        zIndex: '-1',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
      }}
    />
  );
};

export default BackgroundVideo;
