import React from 'react';

import Layout from '../components/layout';
import styles from './Index.module.scss';
import BackgroundVideo from '../components/BackgroundVideo';
import Logo from '../components/Logo';
import CTA from '../components/CTA';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className={styles.container}>
      <Logo />
      <CTA />
      <BackgroundVideo />
    </div>
  </Layout>
);

export default IndexPage;
